import axios from "axios";
import { apiHeaderMultipart, apiHeaderX } from "../../dataServices";
import { API } from "./../../../../config";

export const LicensePlateAnalyze = async ( imagePath, token)=> {
    const config = {
        headers: apiHeaderX(token)
    };
    const imageInfo = {"imageUrl": imagePath}
    return await axios.post(`${API}/api/car_parking/LRP/analyze`,
        imageInfo,
        config
    )
}

export const demoLPRAnalyze = async ( image, token)=> {
    const config = {
        headers: apiHeaderMultipart(token)
    };

    const formData = new FormData();
    formData.append('uploadFile', image);

    return await axios.post(`${API}/api/car_parking/LRP/demo/analyze`,
        formData,
        config
    )
}

export const LicensePlateDemoLogin = async ( username, password)=> {
    const config ={
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
    }
    const loginInfo = {"username": username, "password": password}
    return await axios.post(`${API}/api/car_parking/mobile/auth/login`,
        loginInfo,
        config
    )
}
